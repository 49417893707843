import request from '@/utils/request'

// 获取用户原密码
// export function getOldPassword(reqparams) {
//   return request({
//     url: '/user/check/old-password',
//     method: 'post',
//     data: reqparams
//   })
// }

// 修改密码
export function updatePassword(reqParams) {
  return request({
    url: '/user/update/update-password',
    method: 'put',
    data: reqParams
  })
}

// 验证用户是否第一次登陆
export function getFrist() {
  return request({
    url: '/user/get/check-user-login',
    method: 'get'
  })
}
