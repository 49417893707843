<template>
  <div class="app-container">
    <el-card shadow="always" style="width:500px; margin: 0 auto;" align="center">
      <div slot="header" class="clearfix pwdHeader">
        <span class="pwdTitel">修改密码 {{$store.getters.roles.userInfo.isFirstLogin ? '第一次登陆':''}} {{$store.getters.roles.userInfo.expiredPassword === 1 ? '密码过期':''}} </span>
      </div>
      <el-form
        :model="updateForm"
        status-icon
        :rules="rules"
        ref="updateForm"
        class="demo-ruleForm"
        style=" width:250px;"
      >
        <!-- 如果用户第一次登陆状态为 -->
        <el-form-item v-if="!isFirstLogin" prop="oldPass" :error="errorMsg">
          <el-input
            type="password"
            v-model.trim="updateForm.oldPass"
            autocomplete="off"
            :style="inputwidth"
            placeholder="请输入原密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            type="password"
            v-model.trim="updateForm.pass"
            autocomplete="off"
            :style="inputwidth"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input
            type="password"
            v-model.trim="updateForm.checkPass"
            autocomplete="off"
            :style="inputwidth"
            placeholder="请再次输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">提交</el-button>
          <el-button @click="resetForm()">重置</el-button>
          <el-button type="text" size="small" @click="logout">退出</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { updatePassword } from '@/api/resetPassword.js'

export default {
  data() {
    // 字母 数字 符号 两种以上，长度不少于6位
    const passwordReg = /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (!passwordReg.test(value)) {
          callback(new Error('包含字母和数字，长度不少于8位'))
        } else {
          if (this.updateForm.checkPass !== '') {
            this.$refs.updateForm.validateField('checkPass')
          }
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else {
        if (!passwordReg.test(value)) {
          callback(new Error('包含字母和数字，长度不少于8位'))
        } else {
          if (value !== this.updateForm.pass) {
            callback(new Error('两次输入密码不一致!'))
          } else {
            callback()
          }
        }
      }
    }
    return {
      errorMsg: '', // 验证密码的提醒
      updateForm: {
        pass: '',
        checkPass: '',
        oldPass: '',
        uuId: '',
        userId: ''
      },
      rules: {
        oldPass: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      },
      inputwidth: '300px',
      firstUuId: '',
      isFirstLogin: '',
      type: ''
    }
  },
  created() {},
  mounted() {
    this.testLongin()
    // 禁止通过返回 到系统列表
    history.pushState(null, null, document.URL)
    window.addEventListener('popstate', function() {
      history.pushState(null, null, document.URL)
    })
  },
  methods: {
    // 验证用户是否第一次登陆
    testLongin() {
      this.userInfo = this.$store.getters.roles.userInfo
      this.isFirstLogin = this.userInfo.isFirstLogin
    },

    // 提交表单
    submitForm() {
      this.$refs.updateForm.validate(valid => {
        if (valid) {
          const params = {
            newPassword: this.updateForm.pass,
            oldPassword: this.updateForm.oldPass
          }
          updatePassword(params)
            .then(response => {
              this.logout()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    },
    resetForm() {
      this.$refs.updateForm.resetFields()
    }
  }
}
</script>

<style scoped>
.pwdTitel {
  font-size: 30px;
  color: #303133;
  font-family: "PingFang SC";
}
</style>
